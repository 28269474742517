.rs-navbar-brand {
  padding: 10px !important;
}
.rs-dropdown-item-submenu.rs-dropdown-item-open>.rs-dropdown-menu {
  display: block !important;
  max-height: 250px !important;
  
   
}
 


.rs-navbar-item {
 padding: 0px !important;
  color: #000046 !important;
  
}

  .rs-navbar-nav>.rs-dropdown {
  padding-inline: 15px !important;
}

.rs-navbar-default .rs-dropdown .rs-dropdown-toggle:hover,
.rs-navbar-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle,
.rs-navbar-default .rs-navbar-item:hover {
  background-color: transparent !important;

  color: #000046 !important;
}

.rs-navbar-default {
  background-color: white !important;
}

.rs-navbar .rs-dropdown-item:hover {
  background-color: white !important;
  color: #000046 !important;
}

.rs-navbar .rs-dropdown-item {
  background-color: white !important;
  color: red !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}


 

@media only screen and (max-width: 700px) {
  .rs-navbar-nav>.rs-dropdown {
    padding-inline: 0px !important;
  }
}