@tailwind base;
@tailwind components;
@tailwind utilities;

 
body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
 

@keyframes shuffle {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(-5px, -5px) rotate(-2deg);
  }
  50% {
    transform: translate(5px, 5px) rotate(2deg);
  }
  75% {
    transform: translate(-5px, 5px) rotate(-2deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.photo-card:hover {
  
  animation: shuffle 0.5s ease-in-out;
}


p{
  font-size: 16px !important;
}

 


@media (max-width: 1024px) {
  p{
    font-size: 14px !important;
  }
}