 /* ScrollableComponent.module.css */

.scrollable {
    max-height: 235px;
    overflow-y: auto;
     
  }
  
  /* Custom scrollbar styling */
  .scrollable::-webkit-scrollbar {
    width: 10px;
  }
  
  .scrollable::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 5px;
  }
  
  .scrollable::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Background color of the scrollbar track */
  }
  